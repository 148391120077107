import React, { useState } from 'react';
import { fetchKeyMetrics } from './api';
import './FinancialMetrics.css';

function StockInfo() {
  const [stockSymbol, setStockSymbol] = useState('');
  const [error, setError] = useState('');

  const [name, setName] = useState('');
  const [marketCap, setMarketCap] = useState('');
  const [peRatio, setPeRatio] = useState('');
  const [eps, setEps] = useState('');
  const [debtEquityRatio, setDebtEquityRatio] = useState('');
  const [dividendYield, setDividendYield] = useState('');
  const [freeCashFlowPerShare, setFreeCashFlowPerShare] = useState('');
  const [currentRatio, setCurrentRatio] = useState('');
  const [interestCoverageRatio, setInterestCoverageRatio] = useState('');
  const [roe, setRoe] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const data = await fetchKeyMetrics(stockSymbol);

      if (!data || data.length === 0) {
        setError(`Aucune donnée trouvée pour ${stockSymbol}.`);
        return;
      }

      const metrics = data[0];
      setName(metrics.symbol);
      setMarketCap(metrics.marketCap);
      setPeRatio(metrics.peRatio.toFixed(2));
      setEps(metrics.netIncomePerShare.toFixed(2));
      setDebtEquityRatio(metrics.debtToEquity.toFixed(2));
      setDividendYield(metrics.dividendYield.toFixed(2));
      setFreeCashFlowPerShare(metrics.freeCashFlowPerShare.toFixed(2));
      setCurrentRatio(metrics.currentRatio.toFixed(2));
      setInterestCoverageRatio(metrics.interestCoverage.toFixed(2));
      setRoe(metrics.roe.toFixed(2));
    } catch (err) {
      setError(`Erreur lors de la récupération des données : ${err.message}`);
      // Réinitialiser les valeurs en cas d'erreur
      setName('');
      setMarketCap('');
      setPeRatio('');
      setEps('');
      setDebtEquityRatio('');
      setDividendYield('');
      setFreeCashFlowPerShare('');
      setCurrentRatio('');
      setInterestCoverageRatio('');
      setRoe('');
    }
  };

  const getClass = (value, min, max) => {
    if (typeof value !== 'number') return 'neutral';
    if (value >= min && value <= max) return 'good';
    return 'bad';
  };

  const getResults = () => {
    return [
      { 
        name: 'PE Ratio',
        value: peRatio ? parseFloat(peRatio) : 'N/A',
        unit: '',
        description: "Mesure la valeur de l'action par rapport à ses bénéfices. Elle doit se trouver entre <strong>10 et 20</strong>",
        min: 10, max: 20 
      },
      { 
        name: 'EPS',
        value: eps ? parseFloat(eps) : 'N/A',
        unit: 'USD',
        description: "L'Earnings Per Share est le bénéfice de l'entreprise divisé par le nombre d'actions . On doit trouver une <strong>croissance régulière d'année en année</strong>",
        min: 0, max: Infinity
      },
      { 
        name: 'Debt/Equity Ratio',
        value: debtEquityRatio ? parseFloat(debtEquityRatio) : 'N/A',
        unit: '',
        description: "Niveau d'endettement par rapport aux capitaux propres. Il doit être inférieur à <strong>1.0</strong>",
        min: 0, max: 1.0
      },
      { 
        name: 'Rendement des Dividendes',
        value: dividendYield ? parseFloat(dividendYield*100) : 'N/A',
        unit: '%',
        description: "Dividendes payés par rapport au prix de l'action. Il doit être entre <strong>2% et 6%</strong>",
        min: 2, max: 6 
      },
      { 
        name: 'Flux de trésorie libre',
        value: freeCashFlowPerShare ? parseFloat(freeCashFlowPerShare) : 'N/A',
        unit: 'USD',
        description: "Argent disponible après les dépenses d'exploitation et d'investissement. Il doit être <strong>positif et croissant</strong>",
        min: 0, max: Infinity 
      },
      { 
        name: 'Ratio de liquidité courante',
        value: currentRatio ? parseFloat(currentRatio) : 'N/A',
        unit: '',
        description: "Capacité à couvrir les passifs à court terme. Doit être supérieur à <strong>1.0</strong>",
        min: 1.0, max: Infinity
      },
      { 
        name: 'Ratio de couverture des intérêts',
        value: interestCoverageRatio ? parseFloat(interestCoverageRatio) : 'N/A',
        unit: '',
        description: "Capacité à payer les intérêts sur la dette. Ça doit être supérieur à <strong>1.5</strong>",
        min: 1.5, max: Infinity
      },
      { 
        name: 'Retour sur capitaux propres (ROE)',
        value: roe ? parseFloat(roe*100) : 'N/A',
        unit: '%',
        description: "Rendement généré sur les capitaux propres. Doit être au dessus de <strong>15%</strong>",
        min: 15, max: Infinity
      },
    ];
  };

  return (
    <div>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Calcul de la valeur intrinsèque</h2>
          <p className="mt-4 text-gray-500">
            La valeur intrinsèque est une estimation de la véritable valeur d'une entreprise, indépendamment de son prix de marché actuel. Elle est calculée en analysant les fondamentaux financiers, comme les revenus, les bénéfices, la croissance et les perspectives d'avenir, pour déterminer la performance économique réelle et le potentiel de croissance de l'entreprise.
          </p>
          <div className='flex justify-center mt-8'>
            <form onSubmit={handleSubmit} className="max-w-sm">
              <div className="relative mb-4 flex w-full flex-wrap items-stretch">
                <input 
                  type="text" 
                  className="relative border border-solid m-x-3 p-2" 
                  value={stockSymbol}
                  onChange={(e) => setStockSymbol(e.target.value)}
                  placeholder="ex: AAPL"
                />
                <div className="input-group-append">
                  <button type="submit" className="p-2 bg-sky-700 text-white">Rechercher</button>
                </div>
              </div>
            </form>
          </div>
          {name && (
            <div className='m-5'>
              <h4 className='m-3 text-2xl'>{name}</h4>
              <h4 className='mx-3 text-lg mb-8'>Capitalisation : {parseFloat(marketCap).toLocaleString('fr-FR')} €</h4>
              <dl className="grid grid-cols-1 gap-x-6 gap-y-5 sm:grid-cols-2 sm:gap-y-10 lg:gap-x-8">
                {getResults().map((result, index) => (
                  <div key={index} className='flex items-center border-t border-gray-200'>
                    <div className="pt-4 max-w-md">
                      <div className={`status-ball ${getClass(result.value, result.min, result.max)}`}></div>
                      {result.name}
                      <dt className="font-medium text-gray-900 ml-2">
                        {result.value} {result.unit}
                      </dt>
                      <dd className="mt-2 text-sm text-gray-500" dangerouslySetInnerHTML={{ __html: result.description }}></dd>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StockInfo;
